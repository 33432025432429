<template>
    <nav class="navbar navbar-expand navbar-light bg-white topbar static-top shadow">

    <!-- Sidebar Toggle (Topbar) -->
    <button class="sm-block-b" id="sidebarToggleTop" @click="btntoggle">
        <i :class="{'fa fa-bars':!sidebar, 'fa fa-times' : sidebar}"></i>
    </button>

    <!-- Topbar Navbar -->
    <ul class="navbar-nav ms-auto">

        <!-- Nav Item - Notification -->

        <!-- <li class="nav-item dropdown no-arrow mx-1 ">
            <a class="nav-link dropdown-toggle " href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                <i class="fas fa-bell fa-fw "></i>
              
                <span class="badge badge-danger badge-counter ">3+</span>
            </a>

            <ul class="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="dropdownMenuLink">
                <h6 class="dropdown-header ">
                    Notification
                </h6>
                <a class="dropdown-item d-flex align-items-center " href="# ">
                    <div class="me-3 sm-none">
                        <div class="icon-circle bg-primary ">
                            <i class="fas fa-file-alt text-white "></i>
                        </div>
                    </div>
                    <div>
                        <div class="small text-gray-500 ">October 12, 2021</div>
                        <span class="font-weight-bold ">A new monthly report is ready to download!</span>
                    </div>
                </a>
                <a class="dropdown-item d-flex align-items-center " href="# ">
                    <div class="me-3  sm-none">
                        <div class="icon-circle bg-success ">
                            <i class="fas fa-donate text-white "></i>
                        </div>
                    </div>
                    <div>
                        <div class="small text-gray-500 ">October 12, 2021</div>
                        $290.29 has been deposited into your account!
                    </div>
                </a>
                <a class="dropdown-item d-flex align-items-center " href="# ">
                    <div class="me-3 sm-none">
                        <div class="icon-circle bg-warning ">
                            <i class="fas fa-exclamation-triangle text-white "></i>
                        </div>
                    </div>
                    <div>
                        <div class="small text-gray-500 ">December 2, 2020</div>
                        Spending Alert: We've noticed unusually high spending for your account.
                    </div>
                </a>
                <a class="dropdown-item text-center small text-gray-500 " href="# ">Show All Notification</a>
            </ul>
        </li> -->

        <!-- Nav Item - Messages -->
        <!-- <li class="nav-item dropdown no-arrow mx-1 ">
            <a class="nav-link dropdown-toggle " href="#" role="button" id="messagesDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                <i class="fas fa-envelope fa-fw "></i>
                
                <span class="badge badge-danger badge-counter ">7</span>
            </a>

            <ul class="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="messagesDropdown">
                <h6 class="dropdown-header ">
                    Message Center
                </h6>
                <a class="dropdown-item d-flex align-items-center " href="# ">
                    <div class="dropdown-list-image me-3 ">
                        <img class="rounded-circle " src="assets/img/undraw_profile_1.svg " alt=" ">
                        <div class="status-indicator bg-success "></div>
                    </div>
                    <div class="font-weight-bold ">
                        <div class="text-truncate ">Hi there! Radowan.</div>
                        <div class="small text-gray-500 ">Emily Fowler · 58m</div>
                    </div>
                </a>
                <a class="dropdown-item d-flex align-items-center " href="# ">
                    <div class="dropdown-list-image me-3 ">
                        <img class="rounded-circle " src="assets/img/undraw_profile_2.svg " alt=" ">
                        <div class="status-indicator "></div>
                    </div>
                    <div>
                        <div class="text-truncate ">I have the photos that you ordered last month, how would you like them sent to you?</div>
                        <div class="small text-gray-500 ">Jae Chun · 1d</div>
                    </div>
                </a>
                <a class="dropdown-item d-flex align-items-center " href="# ">
                    <div class="dropdown-list-image me-3 ">
                        <img class="rounded-circle " src="assets/img/undraw_profile_3.svg " alt=" ">
                        <div class="status-indicator bg-warning "></div>
                    </div>
                    <div>
                        <div class="text-truncate ">Last month's report looks great, I am very happy with the progress so far, keep up the good work!</div>
                        <div class="small text-gray-500 ">Morgan Alvarez · 2d</div>
                    </div>
                </a>
                <a class="dropdown-item text-center small text-gray-500 " href="# ">Read More Messages</a>
            </ul>
        </li> -->

        <div class="topbar-divider d-none d-sm-block "></div>

        <!-- Nav Item - User Information -->
        <li class="nav-item dropdown no-arrow mx-1 ">
            <a class="nav-link dropdown-toggle " href="#" role="button" id="userDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                <span class="me-2 d-none d-lg-inline text-gray-600 "> {{user.name}}</span>
                <img class="img-profile rounded-circle " :src=" user.image ?? require('@/assets/backend/img/user.png')">
            </a>

            <ul class="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                <router-link class="dropdown-item " :to="'view-useradmin/'+user.id">
                
                        <i class="fas fa-user fa-sm fa-fw me-2 text-gray-400 "></i> Profile
                    
                </router-link>
                <!-- <a class="dropdown-item " href="# ">
                    <i class="fas fa-cogs fa-sm fa-fw me-2 text-gray-400 "></i> Settings
                </a>
                <a class="dropdown-item " href="# ">
                    <i class="fas fa-list fa-sm fa-fw me-2 text-gray-400 "></i> Activity Log
                </a> -->

                <a class="dropdown-item " @click="logout()">
                    <i class="fas fa-sign-out-alt fa-sm fa-fw me-2 text-gray-400 "></i> Logout
                </a>
            </ul>
        </li>
    </ul>

</nav>
</template>

<script>
export default {
    name: "Header",
    data: () => ({
        user: {},
    }),
    props:{
        sidebar: false
    },
    async created(){
        this.getUser();
    },
    methods: {
        logout(){
            this.clearStorageData();
            this.$router.push('/admin').catch(()=>{});
        },
        getUser(){
            this.user = JSON.parse(localStorage.getItem("user_data"))
            console.log('ata user data',this.user,localStorage.getItem("user_data"))
        },
        clearStorageData() {
            localStorage.removeItem("is_authenticated");
            localStorage.removeItem("auth_token");
            localStorage.removeItem("user_data");
         },
         btntoggle(){
             this.$emit('toggleSidebar')
         }
        },
}
</script>

<style>
#content_wrapper .navbar-expand .navbar-nav .dropdown-menu {
    position: absolute;
    top: 56px;
    left: -112px;
    max-width: 200px;
}
</style>
